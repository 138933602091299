<template>
  <section class="eui-t-parapher-edit" v-if="parapher">
    <div v-if="alertError">
      <EuiAlert color="error" @click:close="alertError = false">
        {{ $t('signworkflows.create.step1.alert.title')}}
        <ul>
          <li v-for="error in errors" :key="error" class="text-white">
            {{ error }}
          </li>
        </ul>
      </EuiAlert>
    </div>
    <AddSignatoriesDialog
      :selectedOrganizations="parapher.recipientOrganizations && parapher.recipientOrganizations.map(value => value.organizationId)"
      :selectedUserType="selectedUserType"
      :selectedUserId="selectedUserId"
      :singleTypeMode="true"
      :forceEnableTabItems="true"
      :options="options"
      @change="setSign"
      @closeDialog="selectedUserType = undefined; selectedSignatoryIndex = undefined; selectedUserId = undefined"
      :open.sync="addSignDialog"/>
    <documentPreviewDialog
      :document="documentDialog"
      @closeDialog="documentDialog = undefined"
      v-if="documentDialog"/>
    <EuiGrid gutters>
      <EuiGridRow>
        <EuiGridColumn>
          <!-- **** TOOLBAR **** -->
          <div class="m-0 flex items-center">
            <EuiButton iconOnly size="large" variant="text" @click="cancel()">
              <EuiIcon name="chevron_left" color="primary"/>
            </EuiButton>
            <EuiHeading :level="1" bold>{{ $t('document.add.table.name') }} {{ parapher.name }}</EuiHeading>
            <div class="flex flex-1 justify-end">
              <EuiButton color="primary" variant="raised" @click="getArchive([$route.params.signBookId, parapher.name])">
                {{ $t('button.download') }}
              </EuiButton>
            </div>
          </div>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$t('signworkflows.table.status')">
            <EuiGridRow>
              <EuiLabel class="text-blue-grey font-bold eui-u-text-base">{{ getSignworkflowsStateText() }}</EuiLabel>
            </EuiGridRow>
            <EuiGridRow>
              <span class="eui-u-ml-2" v-if="refutalReason">{{ parapher.refutalReason }}</span>
            </EuiGridRow>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$i18n.t('signworkflows.subtitle.1')">
            <div class="grid grid-cols-3 gap-8">
              <div>
                <EuiHeading :level="3" bold class="mb-3">{{ $t('signworkflows.create.step1.globalParameters.reminder.title')}}</EuiHeading>
                  <div class="grid grid-cols-2">
                    <div class="text-blue-grey font-bold">{{ $t('signworkflows.create.step1.globalParameters.endingDate.label') }}:</div>
                    <div>{{ $d(new Date(parapher.endingDate), 'short') }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                    <div class="text-blue-grey font-bold">{{ $t('signworkflows.create.step1.globalParameters.notificationEmail.label') }}:</div>
                    <div>{{ parapher.notificationEmail }}</div>
                  </div>
                  <div v-if="parapher.lastAlertDate" class="grid grid-cols-2">
                    <div class="text-blue-grey font-bold">{{ $t('signworkflows.edit.lastReminder') }}:</div>
                    <div>{{ $d(new Date(parapher.lastAlertDate), 'long') }}</div>
                  </div>
                  <div v-if="parapher.alertedRecipients" class="grid grid-cols-2">
                    <div class="text-blue-grey font-bold">{{ $t('signworkflows.edit.remindedRecipients') }}:</div>
                    <div>{{ parapher.alertedRecipients }}</div>
                  </div>
              </div>
              <div>
                <EuiHeading :level="3" bold class="mb-3">{{ $t('signworkflows.create.step1.globalParameters.closingParameters.title')}}</EuiHeading>
                <div class="grid grid-cols-2">
                  <div class="text-blue-grey font-bold">{{ $t('signworkflows.create.step1.globalParameters.reminder.nb.label') }}:</div>
                  <div>{{ $tc('signworkflows.reminder', parapher.alertConfiguration.frequency, { count: parapher.alertConfiguration.frequency }) }}</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="text-blue-grey font-bold">{{ $t('signworkflows.create.step1.globalParameters.reminder.interval.label') }}:</div>
                  <div>{{ $tc('signworkflows.days', parapher.alertConfiguration.intervall, { count: parapher.alertConfiguration.intervall }) }}</div>
                </div>
              </div>
              <div>
                <EuiHeading :level="3" bold class="mb-3">{{ $t('signworkflows.edit.signBookCreator') }}</EuiHeading>
                <div class="flex">
                  <div class="text-blue-grey font-bold mr-3">{{ $t('signworkflows.table.creator') }}:</div>
                  <div>{{ parapher.owner.name }}</div>
                </div>
              </div>
            </div>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$t('signworkflows.documents')">
            <EuiTable v-if="parapher.documents && parapher.documents.length > 0">
              <EuiTableHeader>
                <EuiTableRow>
                  <!--name -->
                  <EuiTableHead/>
                  <EuiTableHead>{{ $t('document.add.table.name') }}</EuiTableHead>
                  <EuiTableHead>Type</EuiTableHead>
                </EuiTableRow>
              </EuiTableHeader>
              <EuiTableBody>
                <!-- table row -->
                <EuiTableRow v-for="(doc, index) in parapher.documents" :key="doc.id" @click.native="showDoc(doc)">
                  <EuiTableCell dataType="input">
                    <EuiIcon :name="doc.extension === 'pdf' ? 'pdf' : 'file'" size="medium"/>
                  </EuiTableCell>
                  <EuiTableCell>{{ doc.name }}</EuiTableCell>
                  <EuiTableCell>
                      {{ parapher.documents[index].typeDescription }}
                  </EuiTableCell>
                </EuiTableRow>
              </EuiTableBody>
            </EuiTable>
          </EuiBlock>
        </EuiGridColumn>
        <EuiGridColumn v-if="canSelectArchivalOrgs">
          <EuiBlock :title="!isDeskRhDomain ? $t('components.templates.parapher.edit.organizations.title') : $t('components.templates.parapher.edit.organizations.title.deskrh')">
            <EuiTable v-if="parapher.recipientOrganizations && parapher.recipientOrganizations.length > 0">
              <EuiTableHeader>
                <EuiTableRow>
                  <!--name -->
                  <EuiTableHead>{{ $t('signworkflows.create.form.organizations.table.name') }}</EuiTableHead>
                </EuiTableRow>
              </EuiTableHeader>
              <EuiTableBody>
                <!-- table row -->
                <EuiTableRow v-for="item in parapher.recipientOrganizations" :key="item.id">
                  <EuiTableCell :main=true>{{ item.name }}</EuiTableCell>
                </EuiTableRow>
              </EuiTableBody>
            </EuiTable>
            <template v-else>
              <!-- add organizations -->
              <p class="mb-4">
                {{ $t('signworkflows.create.form.organizations.none') }}
              </p>
            </template>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <!-- signatories -->
          <EuiBlock :title="$t('components.templates.parapher.edit.signatories')">
            <div class="eui-u-flex eui-u-flex-wrap eui-u-items-center">
              <div>
                <EuiHeading :level="4" bold class="eui-u-mb-3">
                  {{ $t('signworkflows.create.form.signatory.signatureOrdering') }}
                </EuiHeading>
                <div  class="eui-u-mr-5 eui-u-flex eui-u-flex-wrap eui-u-items-center">
                  <EuiRadio disabled name="signatureOrdering" value=""
                            :selected="parapher.allowSimultaneousSteps === false
                            && parapher.allowFirstThenSimultaneousSteps === false">
                    {{ $t('signworkflows.create.form.signatory.sequentially') }}
                  </EuiRadio>
                  <EuiTooltip :content="$t('signworkflows.create.form.signatory.sequentially.tooltip')" placement="top">
                    <template slot="anchor">
                      <EuiIcon class="eui-u-ml-1 eui-u-mr-5" name="infosperso" color="primary"/>
                    </template>
                  </EuiTooltip>
                  <EuiRadio disabled name="signatureOrdering" value=""
                            :selected="parapher.allowSimultaneousSteps === true
                            && parapher.allowFirstThenSimultaneousSteps === false">
                    {{ $t('signworkflows.create.form.signatory.simultaneously') }}
                  </EuiRadio>
                  <EuiTooltip :content="$t('signworkflows.create.form.signatory.simultaneously.tooltip')" placement="top">
                    <template slot="anchor">
                      <EuiIcon class="eui-u-ml-1 eui-u-mr-5" name="infosperso" color="primary"/>
                    </template>
                  </EuiTooltip>
                  <EuiRadio disabled name="signatureOrdering" value=""
                            :selected="parapher.allowSimultaneousSteps === false
                            && parapher.allowFirstThenSimultaneousSteps === true">
                    {{ $t('signworkflows.create.form.signatory.hybrid') }}
                  </EuiRadio>
                  <EuiTooltip :content="$t('signworkflows.create.form.signatory.hybrid.tooltip')" placement="top">
                    <template slot="anchor">
                      <EuiIcon class="eui-u-ml-1" name="infosperso" color="primary"/>
                    </template>
                  </EuiTooltip>
                </div>
              </div>
              <div>
                <EuiHeading :level="4" bold class="eui-u-mb-3">
                  {{ $t('signworkflows.create.form.signatory.refuse') }}
                </EuiHeading>
                <EuiCheckbox v-model="signBook.allowDecline" disabled>
                  {{ $t('signworkflows.create.form.signatory.canRefuse') }}
                </EuiCheckbox>
              </div>
            </div>
            <EuiHeading :level="4" bold class="eui-u-mt-3">
              {{ $t('signworkflows.create.form.signatory.signatoriesOrder')}}
            </EuiHeading>
            <draggable tag="div" class="mt-5"
                       v-model="parapher.steps"
                       handle=".handle"
                       :move="checkMove">
              <transition-group type="transition" name="flip-list">
                <EuiExpansionPanel v-for='(element, index) in parapher.steps'
                                   :key='`sign_${index}`'
                                   class="bg-white"
                                   :class="{'bt-px': index === 0 }">
                  <template v-slot:header>
                    <!-- <EuiIcon :style="{visibility: element.executionDate === null ? 'visible' : 'hidden'}" name="expand_more" color="primary" class="handle mr-4 cursor-move"/>-->
                    <div class="flex-1">
                      <div class="text-blue-grey font-bold mb-2 leading-none">{{ $t('signworkflows.create.step1.signatories.fullname.label') }}</div>
                      <div class="text-xs">{{element.fullname}}</div>
                      <div class="text-xs">{{element.email}}</div>
                    </div>
                    <div class="flex-1">
                      <div class="text-blue-grey font-bold mb-2 leading-none">{{ $t('signworkflows.create.step1.signatories.type.label') }}</div>
                      <div class="text-xs">{{ isDeskRhDomain && element.type === 'externalSignatory' ? $t('signworkflows.create.step1.signatories.type.employee') : $t('signworkflows.create.step1.signatories.type.' + element.type) }}</div>
                    </div>
                    <div class="flex-1" :style="{ visibility: element.type === 'externalSignatory' && parapher.recipientOrganizations && parapher.recipientOrganizations.length > 0 ? 'visible' : 'hidden' }">
                      <div class="text-blue-grey font-bold mb-2 leading-none">{{ $t('signworkflows.create.step1.signatories.organization.label') }}</div>
                      <div class="text-xs" v-if="parapher.recipientOrganizations && parapher.recipientOrganizations.length > 0">{{ parapher.recipientOrganizations.map(value => value.name).join(', ') }}</div>
                    </div>
                    <div class="flex-1" @click.stop>
                      <div class="flex-1" v-if="element.executionDate === null && isEditable">
                        <div class="text-blue-grey font-bold mb-2 leading-none">{{ $t('signworkflows.create.step1.signatories.date.label') }}</div>
                        <EuiInput type="date"
                                  :min="minDate(index)"
                                  v-model="element.limitSignatureDate"/>
                      </div>
                      <div class="flex-1" v-else>
                        <div class="text-blue-grey font-bold mb-2 leading-none">Signature</div>
                        <div class="text-xs" v-if="element.executionDate">{{ $d(new Date(element.executionDate.replace(/-/g, '/')), 'short') }}</div>
                      </div>
                    </div>
                    <div class="flex-1" :style="{ visibility: element.archiveToEdocPerso ? 'visible' : 'hidden' }">
                      <div class="text-blue-grey font-bold mb-2 leading-none">{{ $t('signworkflows.create.step1.signatories.eDocPerso.label')}}</div>
                      <EuiCheckbox disabled v-model="element.archiveToEdocPerso">{{ $t('signworkflows.create.step1.signatories.eDocPerso.archive')}}</EuiCheckbox>
                    </div>
                  </template>
                  <template v-if="(!isDeskRhDomain && isEditable) || (isDeskRhDomain && deskRhFeatures.includes('ParaphCreateUpdate'))" v-slot:actions>
                    <div class="eui-m-expansionPanel__indicator">
                      <EuiIcon name="chevron_down" color="primary"/>
                    </div>
                    <EuiDropdownMenu class="-noToggle" :style="{ visibility: element.executionDate === null || checkIfAbleToSendReminder(element, index) ? 'visible' : 'hidden' }">
                      <EuiList>
                        <EuiListItem style="font-weight: initial"
                                     :button="true"
                                     v-if="checkIfAbleToSendReminder(element, index)"
                                     @click="sendReminder(element)">
                          {{ $t('button.sendReminder') }}
                        </EuiListItem>
                        <EuiListItem style="font-weight: initial"
                                     v-if="element.executionDate === null" :button="true"
                                     @click="selectedUserType = element.type; addSignDialog = true; selectedSignatoryIndex = index; selectedUserId = element.userId">
                          {{ $t('signworkflows.button.change') }}
                        </EuiListItem>
                        <EuiListItem style="font-weight: initial"
                                     v-if="element.executionDate === null"
                                     :button="true"
                                     @click="deleteStep(index)">
                          {{ $t('button.delete') }}
                        </EuiListItem>
                      </EuiList>
                      <template v-slot:anchor>
                        <EuiButton iconOnly color="primary">
                          <EuiIcon name="more_vert"/>
                        </EuiButton>
                      </template>
                    </EuiDropdownMenu>
                  </template>
                  <div>
                    <EuiHeading :level="4" bold class="mb-3">{{ $t('common.advancedParameters')}}</EuiHeading>
                    <EuiCheckbox disabled :checked="element.signerOnlyValidate === '1'" v-if="element.signerOnlyValidate === '1'">
                      <span v-html="$t('signworkflows.create.step1.signatories.signerOnlyValidate.label')"></span>
                    </EuiCheckbox>
                    <EuiTextField adaptSize class="mt-2" noResize>
                      <EuiLabel>{{ $t('signworkflows.create.step1.signatories.comment.label') }}</EuiLabel>
                      <!--<EuiTextarea v-if="element.executionDate === null" fullWidth :rows="2" v-model="element.comment" autocomplete="personnal-comment"/>-->
                      <p v-if="element.comment" class="ml-3" v-html="sanitize(element.comment)"></p>
                      <p v-else class="ml-3">{{ $t('signworkflows.edit.globalMessage.none') }}</p>
                    </EuiTextField>
                  </div>
                </EuiExpansionPanel>
              </transition-group>
            </draggable>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$t('signworkflows.create.step1.thirdPartyEmails.title')">
            <EuiList v-if="parapher.thirdParties && parapher.thirdParties.length > 0">
              <EuiListItem v-for="item in parapher.thirdParties" :key="item.thirdPartyEmail">
                <template v-slot:graphic>
                  <EuiIcon name="person"/>
                </template>
                {{ item.thirdPartyEmail }}
              </EuiListItem>
            </EuiList>
            <p v-else>{{ $t('signworkflows.edit.thirdPartiesEmails.none')}}</p>
          </EuiBlock>
        </EuiGridColumn>
        <EuiGridColumn>
          <EuiBlock :title="$t('signworkflows.create.step1.comment.title')">
            <template v-if="parapher.globalMessage">
              <EuiLabel>{{ $t('signworkflows.create.step1.comment.label') }}</EuiLabel>
              <p>{{ parapher.globalMessage }}</p>
            </template>
            <p v-else>{{ $t('signworkflows.edit.globalMessage.none') }}</p>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow v-if="isEditable">
        <EuiGridColumn>
          <div class="flex justify-end mt-5">
            <EuiButton v-if="!isDeskRhDomain || (isDeskRhDomain && deskRhFeatures.includes('ParaphCreateUpdate'))" variant="text" color="primary" @click="cancel()">{{ $t('button.cancel') }}</EuiButton>
            <EuiButton v-if="!isDeskRhDomain || (isDeskRhDomain && deskRhFeatures.includes('ParaphCreateUpdate'))" variant="raised" color="primary" @click="save()" :disabled="haveSameProperties">{{ $t('button.save') }}</EuiButton>
          </div>
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import initials from '../../../utils/initials';
import documentPreviewDialog from '../../../pages/paraphers/dialogs/DocumentPreviewDialog';
import mixin from '../../../mixins/compare';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import DOMpurify from 'dompurify';
export default {
  mixins: [mixin],
  props: {
    signBook: {
      type: Object,
      default() {
        return null;
      },
    },
    fromTab: {
      type: String,
      default() {
        return null;
      },
    },
  },
  computed: {
    ...mapGetters({
      options: 'ModuleEdocSign/auth/options',
      profile: 'ModuleEdocSign/auth/profile',
    }),
    ...mapState({
      isDeskRhDomain: (state) => state.ModuleEdocSign.auth.domains.isDeskRh,
    }),
    canSelectArchivalOrgs() {
      if (this.$store.state.ModuleEdocSign.auth.profile.options && this.$store.state.ModuleEdocSign.auth.profile.options.selectCustomerOrgsArchival) {
        return this.$store.state.ModuleEdocSign.auth.profile.options.selectCustomerOrgsArchival.value === '1';
      }
      return true;
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    isEditable() {
      const isExecuted = (currentStep) => currentStep.executionDate !== null;
      let endingDate = new Date(this.parapher.endingDate);
      endingDate = new Date(endingDate.getFullYear(), endingDate.getMonth(), endingDate.getDate(), 23, 59, 59);
      const isParapherExpired = (endingDate < Date.now() && (this.parapher.finalizedDate === null || this.parapher.finalizedDate === undefined));
      const atLeastOneStepNotSigned = !this.parapher.steps.every(isExecuted);
      return (isParapherExpired ? atLeastOneStepNotSigned : atLeastOneStepNotSigned && endingDate >= Date.now());
    },
    deskRhFeatures() {
      return JSON.parse(localStorage.getItem('deskRhFeatures'));
    },
    haveSameProperties() {
      return isEqual(this.parapher, this.tmpParapher);
    },
  },
  watch: {
    signBook(value) {
      this.parapher = JSON.parse(JSON.stringify(value));
      this.tmpParapher = cloneDeep(this.parapher);
    },
    deep: true,
  },
  data() {
    return {
      parapher: undefined,
      tmpParapher: undefined,
      documentDialog: undefined,
      alertError: false,
      selectedUserType: undefined,
      selectedSignatoryIndex: undefined,
      selectedUserId: undefined,
      addSignDialog: false,
      errors: [],
      refutalReason: false,
    };
  },
  components: {
    draggable: () => import(/* webpackChunkName: "vuedraggable" */ 'vuedraggable'),
    AddSignatoriesDialog: () => import(/* webpackPrefetch: true */ '../../../pages/paraphers/dialogs/AddSignatoriesDialog'),
    documentPreviewDialog,
  },
  methods: {
    sanitize(text) {
      return DOMpurify.sanitize(text);
    },
    getSignworkflowsStateText() {
      const finalizedDate = this.parapher.finalizedDate
        ? this.parapher.finalizedDate.split(' ')[0].split('-').reverse().join('/') : undefined;
      const nextStepDate = this.parapher.nextStepDate
        ? this.parapher.nextStepDate.split(' ')[0].split('-').reverse().join('/') : undefined;

      let text = {};

      if (this.parapher.finalizedDate && !this.parapher.steps.find((x) => parseInt(x.refuted))) {
        text = this.$i18n.t('signworkflows.table.state.completed', { date: finalizedDate });
      } else if (this.parapher.finalizedDate && this.parapher.steps.find((x) => parseInt(x.refuted))) {
        const refusedSigner = this.parapher.steps[this.parapher.nbStepsRealized - 1].signerName;
        text = this.$i18n.t('signworkflows.table.state.refused', { person: refusedSigner, date: finalizedDate });
        this.refutalReason = true;
      } else if (new Date(this.parapher.endingDate) < Date.now()) {
        text = this.$i18n.t('signworkflows.table.state.expired', { person: this.parapher.nextSignerName, date: nextStepDate });
      } else if (this.parapher.nextSignerName !== this.parapher.owner.fullname) {
        text = this.$i18n.t('signworkflows.table.state.pending', { person: this.parapher.nextSignerName, date: nextStepDate });
      } else {
        text = this.$i18n.t('signworkflows.table.state.waiting-for-action', { date: nextStepDate });
      }
      return text;
    },
    checkIfAbleToSendReminder(element, index) {
      if (this.parapher.allowSimultaneousSteps === true || this.parapher.allowFirstThenSimultaneousSteps === true) {
        return element.executionDate === null;
      }
      if (this.parapher.allowSimultaneousSteps === false || this.parapher.allowFirstThenSimultaneousSteps === false) {
        if (index === 0 && element.executionDate === null) {
          return true;
        } else if (index !== 0 && this.parapher.steps[index - 1].executionDate !== null && element.executionDate === null) {
          return true;
        }
        return false;
      }
    },
    base64ToFile(doc) {
      const binary = window.atob(doc.fileContent);
      const converter = new ArrayBuffer(binary.length);
      const array = new Uint8Array(converter);
      for (let i = 0; i < binary.length; i += 1) {
        array[i] = binary.charCodeAt(i);
      }
      return new File([converter], doc.name, { type: 'application/pdf' });
    },
    showDoc(doc) {
      this.getDocContent({ id: doc.signBookDocumentId })
        .then((response) => {
          doc.fileContent = response.fileContent;
          this.documentDialog = { id: doc.signBookDocumentId, content: this.base64ToFile(doc), fileContent: doc.fileContent };
        });
    },
    setSign(signatories) {
      this.$set(this.parapher.steps[this.selectedSignatoryIndex], 'initials', `${initials(signatories.firstname, signatories.lastname)}`);
      this.$set(this.parapher.steps[this.selectedSignatoryIndex], 'fullname', signatories.fullname);
      this.$set(this.parapher.steps[this.selectedSignatoryIndex], 'email', signatories.email);
      this.$set(this.parapher.steps[this.selectedSignatoryIndex], 'userId', signatories.userId);
      this.$set(this.parapher.steps[this.selectedSignatoryIndex], 'type', signatories.type);
      if (this.parapher.steps[this.selectedSignatoryIndex].eDocPersoCreated === true &&
        !this.parapher.steps[this.selectedSignatoryIndex].archiveToEdocPerso) {
        this.$set(this.parapher.steps[this.selectedSignatoryIndex], 'archiveToEdocPerso', true);
      }
    },
    minDate(index) {
      if (this.parapher.allowFirstThenSimultaneousSteps ||
        (index === 0) ||
        (index > 0 &&
          (this.parapher.steps[index - 1].executionDate !== null ||
          this.parapher.steps[index - 1].limitSignatureDate === undefined)
        )
      ) {
        return this.today;
      }
      return this.parapher.steps[index - 1].limitSignatureDate;
    },
    checkMove(event) {
      const { futureIndex } = event.draggedContext;
      return this.parapher.steps[futureIndex].executionDate === null;
    },
    ...mapActions({
      deleteStepAction: 'ModuleEdocSign/parapheurs/deleteStep',
      getDocContent: 'ModuleEdocSign/parapheurs/getDocContent',
      getArchive: 'ModuleEdocSign/parapheurs/getArchive',
      sendReminderAction: 'ModuleEdocSign/parapheurs/sendReminder',
      updateStepDeadline: 'ModuleEdocSign/parapheurs/updateStepDeadline',
      updateStepSignatory: 'ModuleEdocSign/parapheurs/updateStepSignatory',
      pushAlert: 'application/pushAlert',
      updateParaphAlert: 'ModuleEdocSign/parapheurs/updateParaphAlert',
    }),

    async sendReminder(step) {
      const id = step.signBookStepId;
      await this.sendReminderAction({ signBookStepId: id });
      this.$emit('reload');
    },

    deleteStep(index) {
      const id = this.parapher.steps[index].signBookStepId;
      this.deleteStepAction({ signBookStepId: id }).then(() => {
        this.parapher.steps.splice(index, 1);
      });
    },

    cancel() {
      if (this.$store.state.route.from.fullPath !== '/' && this.fromTab === undefined) {
        this.$router.push(this.$store.state.route.from.fullPath);
      } else if (this.fromTab) {
        this.$router.push({ name: 'signworkflows', params: { tab: this.fromTab } });
      } else {
        this.$router.push({ name: 'signworkflows', params: { tab: 'inbox' } });
      }
    },

    validate() {
      this.errors = [];
      for (let i = 0; i < this.parapher.steps.length; i++) {
        if (i > 0 &&
          Date.parse(this.parapher.steps[i - 1].limitSignatureDate) >
          Date.parse(this.parapher.steps[i].limitSignatureDate)) {
          this.errors.push(this.$i18n.t('signworkflows.step.limitSignatureDate.error'));
          this.alertError = true;
          document.getElementsByClassName('eui-o-layout__main')[0].scrollTop = 0;
          return false;
        }
      }
      return true;
    },

    save() {
      if (this.validate()) {
        const promises = [];
        if (JSON.stringify(this.parapher.alertConfiguration) !== JSON.stringify(this.signBook.alertConfiguration)) {
          this.parapher.alertConfiguration.intervall = this.parapher.alertConfiguration.intervall * (60 * 60 * 24);
          promises.push(this.updateParaphAlert({
            signBookId: this.$route.params.signBookId,
            alertConfiguration: this.parapher.alertConfiguration,
          }));
        }
        this.parapher.steps.forEach((step, index) => {
          if (step.limitSignatureDate !== this.signBook.steps[index].limitSignatureDate) {
            promises.push(this.updateStepDeadline({
              signBookStepId: step.signBookStepId,
              date: step.limitSignatureDate,
            }));
          }
          if (step.userId !== this.signBook.steps[index].userId) {
            promises.push(this.updateStepSignatory({
              signBookStepId: step.signBookStepId,
              signer: {userId: step.userId},
            }));
          }
          /*
          if (this.parapher.steps[i].signBookStepId !== this.signBook.steps[i].signBookStepId) {
            promises.push(this.updateStepSignatory({
              signBookStepId: signBookStepIds[i],
              signer: {userId: this.parapher.steps[i].userId},
            }));
          }
          if (this.parapher.steps[i].userId !== this.signBook.steps[i].userId) {
            promises.push(this.updateStepSignatory({
              signBookStepId: signBookStepIds[i],
              signer: {userId: this.parapher.steps[i].userId},
            }));
          }
          */
        });
        Promise.all(promises)
          .then(() => {
            this.pushAlert({
              message: this.$i18n.t('store.signworkflows.update.success'),
              type: 'success',
            });
            this.$router.push({ name: 'signworkflows' });
          })
          .catch(() => {
            this.pushAlert({
              message: this.$i18n.t('store.signworkflows.update.error'),
              type: 'error',
            });
          });
      }
    },
  },
};
</script>
