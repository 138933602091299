<template>
  <EuiContainer>
    <TemplateParapherEdit :signBook="parapher" :fromTab="fromTab" @reload="getSignBookById(signBookId)"/>
  </EuiContainer>
</template>

<script>
import { mapActions } from 'vuex';
import TemplateParapherEdit from '../../components/templates/parapher/edit';

export default {
  props: {
    signBookId: {
      type: [String, Number],
      required: false,
    },
    fromTab: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  components: {
    TemplateParapherEdit,
  },
  computed: {
    parapher: {
      get() {
        return this.$store.state.ModuleEdocSign.parapheurs.signBook;
      },
    },
  },
  methods: {
    ...mapActions({
      getSignBookById: 'ModuleEdocSign/parapheurs/getSignBookById',
    }),
  },
  /**
   * Available watchers for this page.
   */
  watch: {
    signBookId(value) {
      this.getSignBookById(value);
    },
  },
  mounted() {
    this.getSignBookById(this.signBookId);
  },
};
</script>
